import mapboxgl from 'mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoibGlxdWlkbWVsYm91cm5lIiwiYSI6ImNqNzFqNHgyaDAxOTYyd3J4cWNoNzV1ZHQifQ.VdAhhEWu-ecfbOlIanXjXQ';

var mapboxStyle = 'mapbox://styles/liquidmelbourne/cj75fm7uw4est2rqdlc2hasrl';

var bp_sm = '800';



// This will let you use the .remove() function later on
if (!('remove' in Element.prototype)) {
  Element.prototype.remove = function() {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}


var mapCenter = [133.5367715, -34.9485491448568];
var mapZoom = 4;
var mapMaxZoom = 13;
var mapMarker = null;

var pinSvg = '<svg class="icon" viewBox="0 0 20 20" x="0" y="0"><path d="M10 19a.78.78 0 0 0 .56-.23C10.82 18.5 17 12.18 17 8a7 7 0 0 0-7-7 7 7 0 0 0-7 7c0 4.18 6.18 10.5 6.44 10.77A.78.78 0 0 0 10 19zM7.65 8A2.35 2.35 0 1 1 10 10.39 2.35 2.35 0 0 1 7.65 8z" fill="currentColor"/></svg>';

var containerId = 'project-map-container';
var mapId = 'project-map';

$(document).ready(function() {
  if ( $('#' + mapId).length > 0 ) {
    mapInit();
  }
})



function mapInit() {

  var markerCoord = [parseInt($('#'+mapId).attr('data-lng')),parseInt($('#'+mapId).attr('data-lat'))];
  var markerTitle = $('#'+mapId).attr('data-title');
  var markerAddress = $('#'+mapId).attr('data-address');
  var markerLink = $('#'+mapId).attr('data-gmaps-link');

  var markers = {
    "type": "FeatureCollection",
    "features": [{
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": markerCoord
      },
      "properties": {
        "title": markerTitle,
        "description": markerAddress,
        "link": markerLink
      }
    }],
  };

  var mapCenter = markerCoord;
  var mapZoom = 4;

  var map = new mapboxgl.Map({
    container: mapId,
    style: mapboxStyle,
    center: mapCenter,
    zoom: mapZoom, // between 0 and 22.
    attributionControl: false, // disable bottom right credits and links
    scrollZoom: true
  });

  // disable map rotation using right click + drag
  map.dragRotate.disable();
  // disable map rotation using touch rotation gesture
  map.touchZoomRotate.disableRotation();

  map.doubleClickZoom.disable();

  // == Marker
  // var marker = document.createElement('div');
  // marker.className = 'marker project-marker';
  // marker.innerHTML += '<div class="pin">'+pinSvg+'</div>';
  // marker.innerHTML += '<div class="content"><p><span class="title">'+markerTitle+'</span><br>'+markerAddress+'</p></div>';
  // // Add Marker to Map
  // var mapMarker = new mapboxgl.Marker(marker)
  //   .setLngLat(markerCoord)
  //   .addTo(map);


   // load layer with markers
  map.on('load',function(e) {
    
    map.addSource('markers', {
      type: 'geojson',
      data: markers
    }); 
      
  })

  markers.features.forEach(function(marker) {
    var el = document.createElement('div');
    el.className = 'marker';
    var $element = $(el).html('<div class="pin">'+pinSvg+'</div><h5>'+marker.properties.title+'</h5>');
    // var $element = $(el).html('<div class="dot"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 265 265"><circle fill="" cx="132.5" cy="132.5" r="126.3"/><g fill="currentColor"><path d="M120.3 77.7h24v109.7h-24zM155 170.5c4.2-9.9 18.1-9.9 22.3 0 1.2 2.9 1.2 6.3 0 9.2-4.2 9.9-18.1 9.9-22.3 0-1.3-3-1.3-6.3 0-9.2"/></g></svg></div><h5>'+marker.properties.title+'</h5>');
    var options = {
      offset: [0, 0],
    }
    var m = new mapboxgl.Marker($element[0], options)
      .setLngLat(marker.geometry.coordinates)
      .addTo(map);

    mapMarker = m;


    el.addEventListener('click', function(e) {
      var activeItem = document.getElementsByClassName('active');
      // 1. Fly to the point
      flyTo(marker);
      // 2. Close all other popups and display popup for clicked store
      createPopUp(marker);
    });


  });


  // == Custom zoom
  var zoomControls = $('#'+containerId).find('.map-zoom-controls .control');
  zoomControls.on('click', function(e) {
    e.preventDefault();

    var options = {
      offset: [0, 0]
    }
    // zoom in
    if ( $(this).hasClass('zoom-in') ) {
      map.zoomIn(options);
    } 
    // zoom out
    else if ( $(this).hasClass('zoom-out') ) {
      map.zoomOut(options);
    }
  })


  function flyTo(currentFeature) {
    // map.flyTo({
    //   center: currentFeature.geometry.coordinates,
    //   zoom: 6
    // });
    var offset = Modernizr.mq('(max-width: '+bp_sm+'px)') ? [0, 60] : [0,0];
    map.easeTo({
      center: currentFeature.geometry.coordinates,
      offset: offset
      // zoom: 4
      // zoom: mapZoom
    });
  }

  function createPopUp(currentFeature) {

    // Check if there is already a popup on the map and if so, remove it
    var popUps = document.getElementsByClassName('mapboxgl-popup');
    if (popUps[0]) popUps[0].remove();
    $('#'+mapId).find('.marker').removeClass('active');

    var $marker = $(mapMarker._element);
    $marker.addClass('active');


    var title = '<h5 class="title">' + currentFeature.properties.title + '</h5>';
    var details = '<p class="content">' + currentFeature.properties.description + '</p>';
    var link = currentFeature.properties.link ? '<a href="'+ currentFeature.properties.link +'" target="_blank" class="text-link">Get directions on Google Maps</a>' : '';

    var options = {
      closeOnClick: false, 
      offset:0,
      anchor: 'bottom'
    };

    var popup = new mapboxgl.Popup(options)
      .setLngLat(currentFeature.geometry.coordinates)
      .setHTML(title + details + link)
      .addTo(map);

    popup.on('close', function(e) {
      $marker.removeClass('active');
    })
  }

}

