<template>
  <h1 class="heading statement">
    <transition 
      @before-enter="beforeEnter"
      @enter="enter"
      :css="false">
        <div ref="textToSplit" class="splittextDiv" v-if="load" v-html="message">{{message}}</div> 
    </transition>

  </h1>
</template>

<script>
//https://vuejs.org/v2/guide/transitions.html
//https://codepen.io/sdras/pen/MJedjd

import { TimelineLite } from 'gsap';
import SplitText from "../modules/gsap-bonus/SplitText";

export default {
  //props: ['message', 'loop'],
  props: {
    message: {
      type: String
    },
    loop: {
      default: false,
      type: Boolean
    },
    elementfirst : {
      type: String,
      default: null
    },
    elementsecond : {
      type: [String, Element],
      default: null
    },
  },
  data() {
    return {
      load: false,
      timeline: null,
      sentence: null,
      splitText: this.message
    }
  },
  methods: {
    beforeEnter(el) {
      // console.log("in beforeEnter")
    },
    enter(el, done) {
        //console.log("in enter");
        //console.log(this.loop);
        var loader = this.load;
        var shouldLoop = this.loop;

        // console.log(this.elementfirst);

        

        /*let charSplit = new SplitText(el, { type: "chars" });
        let charCount = charSplit.chars.length;
        let tl = new TimelineLite({
            onComplete: done
        });

        tl.delay(1)
        for (var i = 0; i < charCount; i++) {
          tl.to(charSplit.chars[i], 1, {autoAlpha:1, clipPath:"inset(0px 0px 0% 1px)", ease:Expo.easeOut}, i * 0.05);
        }
        */

        //TweenMax.set(el, {x:100, y:50, opacity:1});

        let wordSplit = new SplitText(el, { type: "words" });
        let wordCount = wordSplit.words.length;
        let tlWorld = new TimelineLite({
            onComplete: function(){
              //console.log("onComplete 4")
              //document.getElementById("homepageIntro").style.opacity = "1";
              if(shouldLoop == true) this.restart();
           }
        });

        //if(this.prePostElementToAnimateFirst) console.log("animatefirst")
        TweenMax.set('#homepageIntro', {autoAlpha:0, y: 20});
        TweenMax.set('#banner-scroll', {autoAlpha:0, y: 20});

        for (var i = 0; i < wordCount; i++) {
          //tlWorld.to(wordSplit.words[i], 2.5, {autoAlpha:1,y:0, clipPath:"inset(0% 0% 0% 0%)", ease:Expo.easeOut}, i * 0.2);
          TweenMax.set(wordSplit.words[i], {y:30, opacity:0, clipPath:"inset(100% 0% 0% 0%)"});
        }


        tlWorld.delay(0.25)

        for (var i = 0; i < wordCount; i++) {
          //tlWorld.to(wordSplit.words[i], 2.5, {autoAlpha:1,y:0, clipPath:"inset(0% 0% 0% 0%)", ease:Expo.easeOut}, i * 0.2);
          tlWorld.to(wordSplit.words[i], 3, {autoAlpha:1, y:0, ease:Expo.easeOut, clipPath:"inset(0% 0% 0% 0%)",  force3D:true}, i * 0.2);
        }

        tlWorld.to('#homepageIntro', 2, {autoAlpha:1, y:0, ease:Expo.easeOut}, i * 0.2 + 0.5 )
        tlWorld.to('#banner-scroll', 2, {autoAlpha:1, y:0, ease:Expo.easeOut}, i * 0.2 + 0.8 )




    }
  }, 
  mounted() { 
    const { textToSplit } = this.$refs;
    this.load = true;
  } 
};

</script>

<style>
.home-page .copy{

}
.splittextDiv > div{
  display:inline-block;
  position:relative;
  /*clip-path: inset(100% 0% 0% 0%);
  transform: translate(0%, 20%);*/
  visibility:visibe;
  -webkit-font-smoothing:antialiased;
  line-height: 1.24;
  letter-spacing: -1px;
}
</style>