import mapboxgl from 'mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoibGlxdWlkbWVsYm91cm5lIiwiYSI6ImNqNzFqNHgyaDAxOTYyd3J4cWNoNzV1ZHQifQ.VdAhhEWu-ecfbOlIanXjXQ';

var mapboxStyle = 'mapbox://styles/liquidmelbourne/cj75fm7uw4est2rqdlc2hasrl';

var bp_sm = '800';

$(document).ready(function() {

  projectsMap.init();

})

var projectsMap = {
  mapContainerId: 'projects-map-container',
  mapId: 'projects-map',
  mapClusterMaxZoom: 14,
  mapZoom: 13,
  minZoom: 0,
  maxZoom: 12,
  mapPadding: 100,
  pinSvg: '<svg class="icon" viewBox="0 0 20 20" x="0" y="0"><path d="M10 19a.78.78 0 0 0 .56-.23C10.82 18.5 17 12.18 17 8a7 7 0 0 0-7-7 7 7 0 0 0-7 7c0 4.18 6.18 10.5 6.44 10.77A.78.78 0 0 0 10 19zM7.65 8A2.35 2.35 0 1 1 10 10.39 2.35 2.35 0 0 1 7.65 8z" fill="currentColor"/></svg>',
  mapMarkers: [],
  mapInit: false,
  hasMoved: false,
  pinClicked: false,
  clusterRadius: 60,
  resetFitBounds: function(animate) {
    var _this = this;

    if ( _this.mapInit == false ) { return; }
    // var easing = !animate ? 'linear: true' : '';
    if(_this.mapMarkers.length < 2) {
      var options = {
        padding: {top: _this.mapPadding, bottom:_this.mapPadding, left: _this.mapPadding, right: _this.mapPadding},
        maxZoom: 16
      }
    } else {
      var options = {
        padding: {top: _this.mapPadding, bottom:_this.mapPadding, left: _this.mapPadding, right: _this.mapPadding},
        // maxZoom: _this.mapZoom
      }
    }
    

    if ( !animate ) {
      options.linear = true;
    }

    if ( !status ) {
      status = null;
    }

    _this.map.fitBounds(_this.mapBbox, options);

  },

  // Create layers with all features for this query (all projects included)
  loadPins: function() {
    var _this = this;

    _this.map.addSource('projects', {
      type: 'geojson',
      data: _this.data,
    });

    _this.map.loadImage(_this.mapPin, function(error, image) {
      if (error) throw error;
      _this.map.addImage('pin', image);
   
      _this.map.addLayer({
        "id": "pins",
        "type": "symbol",
        "source": "projects",
        "layout": {
          "icon-image": "pin",
          "icon-size": .5,
          "icon-allow-overlap": true
        }
      });

      _this.updateMapPosition(_this.projects);

    });

    _this.map.loadImage(_this.mapPinActive, function(error, image){
      if (error) throw error;
      _this.map.addImage('pin-active', image);

      _this.map.addLayer({
        "id": "pins-hover",
        "type": "symbol",
        "source": "projects",
        "layout": {
          "icon-image": "pin-active", //gold
          "icon-size": .5,
          "icon-allow-overlap": true,
        },
        "filter": ["==", "id", ""]
      });

    });

    // === Pin hover state
    _this.map.on('mousemove', 'pins', function (e) {
      _this.map.getCanvas().style.cursor = 'pointer';
      // issue: if pin hovered pin is behind another pin, the gold "pin-hover" goes over the blue pins and the icons flicker..
      // _this.map.setFilter("pins-hover", ["==", "id", e.features[0].properties.id]);
    });
    _this.map.on('mouseleave', 'pins', function () {
      _this.map.setFilter("pins-hover", ["==", "id", ""]);
      _this.map.getCanvas().style.cursor = '';
    });


    // === Click on pin: show popup
    _this.map.on('click', 'pins', function(e) {
      var feature = e.features[0];
      var prop = feature.properties;
        
      // Add active style to this pin
      // _this.setActiveSymbol(feature);

      // Fly to the point and center pin + popup
      _this.map.flyTo({
        center: feature.geometry.coordinates,
        zoom: _this.map.getZoom() < 1 ? 1 : _this.map.getZoom(), // current zoom or zoom in if max zoom out (no room for popup)
        offset: [0,-80] // fit popup in view
      });

      // Close all other popups and display popup for clicked store
      _this.createPopUp(feature);

    })
  },

  // Fly to correct position
  updateMapPosition: function(features) {
    var _this = this;

    if ( features ) {
      _this.mapBbox = new mapboxgl.LngLatBounds();
      features.forEach(function(f) {
        _this.mapBbox.extend(f.geometry.coordinates);
      });
    }

    _this.resetFitBounds(false);
    _this.map.once('moveend', fitBoundsEnd);

    function fitBoundsEnd() {
      $('#'+_this.mapContainerId).removeClass('loading');
      _this.map.off('moveend', fitBoundsEnd);
    }
    _this.map.on('resize',function(){
      // recenter map (with animation)
      _this.resetFitBounds(true);

    })
  },

  // Active pin style
  setActiveSymbol: function(feature) {

    var _this = this;
    // get current featured (looking for id)
    var p = _this.projects.filter(function(f) {
      return f.properties.id === feature.properties.id;
    }, feature);

    var geojson = {
      "type": "FeatureCollection",
      "features": p
    };

    if ( _this.map.getSource('active_pin') ) {
      _this.map.removeSource('active_pin');
    }  
    if ( _this.map.getLayer('pin-active') ) {
      _this.map.removeLayer('pin-active');
    }

    _this.map.addSource('active_pin', {
      type: 'geojson',
      data: geojson,
    });

    _this.map.addLayer({
      "id": "pin-active",
      "type": "symbol",
      "source": "active_pin",
      "layout": {
        "icon-image": "pin-active", // already loading for pins-hover
        "icon-size": .5,
        "icon-allow-overlap": true,
      }
    });

  },

  // Remove active pin style
  removeActiveSymbol: function() {
    var _this = this;
    if ( _this.map.getSource('active_pin') ) {
      _this.map.removeSource('active_pin');
    }
    if ( _this.map.getLayer('pin-active') ) {
      _this.map.removeLayer('pin-active');
    }
  },

  removePopup: function() {
    if ( this.mapInit == false ) { return; }
    var popUps = document.getElementsByClassName('mapboxgl-popup');
    // Check if there is already a popup on the map and if so, remove it
    if (popUps[0]) popUps[0].remove();
  },

  // Add popup to the DOM
  createPopUp: function(currentFeature) {
    var _this = this;
    if ( _this.mapInit == false ) { return; }
    var popUps = document.getElementsByClassName('mapboxgl-popup');
    // Check if there is already a popup on the map and if so, remove it
    if (popUps[0]) popUps[0].remove();

    var lngLat = currentFeature.geometry.coordinates;

    var prop = currentFeature.properties;

    var location = prop.location.length < 1 ? '' : '<h4 class="location">'+ prop.location +'</h4>';

    var markup = [
    '<div class="header">'+ location +'</div>',
      '<a href="'+ prop.link +'" class="map-popup-project">',
        '<span class="img bg-img-cover" style="background-image:url(\''+prop.imgUrl+'\')"></span>',
        '<span class="body">',
          '<h3 class="heading">'+prop.title+'</h3>',
          '<div><span class="text-link grey">View Client</span></div>',
        '</span>',
      '</a>',
    ].join('');

    var popup = new mapboxgl.Popup({
      closeOnClick: true,
      anchor: 'top',
    })
    .setLngLat(lngLat)
    .setHTML(markup)
    .addTo(_this.map);

    // if ($('html').hasClass('touch')) {
    //     _this.map.dragPan.enable();
    //   }

    // update close icon
    // var path = 'http://' + window.location.hostname;
    // $('.mapboxgl-popup-close-button').html('<img alt="close" src="'+ path +'/images/mapbox/map-close.png" />');

    // popup.on('close',function(){
      // _this.removeActiveSymbol();
      // _this.pinClicked = false;
      // if ($('html').hasClass('touch')) {
      //     _this.map.dragPan.disable();
      //   }
    // })

  },

  // Init map
  initMap: function(destination) {
    var _this = this;

    if ( _this.mapInit == true ) { return; }
    if ( _this.projects.length < 1 ) { return; }

    // position map to fit all pins.
    _this.mapBbox = new mapboxgl.LngLatBounds();
    _this.projects.forEach(function(f) {
      _this.mapBbox.extend(f.geometry.coordinates);
    });

    if ( $('#' + _this.mapId).length < 1 ) { return; }



    _this.map = new mapboxgl.Map({
      container: _this.mapId,
      style: mapboxStyle,
      zoom: _this.mapZoom,
      maxZoom: _this.maxZoom,
      minZoom: _this.minZoom,
      attributionControl: false,
      scrollZoom: false,
    });


    // disable map rotation using right click + drag
    _this.map.dragRotate.disable();

    // disable drag on touch devices
    // if ($('html').hasClass('touch')) {
    //   _this.map.dragPan.disable();
    // }
      
    _this.mapPin = $('#'+_this.mapId).attr('data-marker');
    _this.mapPinActive = $('#'+_this.mapId).attr('data-marker-active');


    _this.map.on('load',function(e) {

      // == Add markers to map
      _this.loadPins();


      // == Custom zoom
      var zoomControls = $('#'+_this.mapContainerId).find('.map-zoom-controls .control');
      zoomControls.on('click', function(e) {
        e.preventDefault();

        // close marker popup + unactive pin and listing
        if ( _this.map.getLayer('pins-hover') ) {
          $('.mapboxgl-popup-close-button').trigger('click');
        }

        var options = {
          // offset: [_this.zoomOffsetX, 0]
        }
        // zoom in
        if ( $(this).hasClass('zoom-in') ) {
          _this.map.zoomIn(options);
        } 
        // zoom out
        else if ( $(this).hasClass('zoom-out') ) {
          _this.map.zoomOut(options);
        }
      })

    })

    _this.mapInit = true;
  },

  // Destroy map
  destroyMap: function() {
    if ( this.mapInit == false ) { return; }
    this.map.remove();
    this.mapInit = false;
  },

  init: function() {
    var _this = this;

    // This will let you use the .remove() function later on for popup
    if (!('remove' in Element.prototype)) {
      Element.prototype.remove = function() {
      if (this.parentNode) {
        this.parentNode.removeChild(this);
      }
      };
    } 

    if ( window.allProjects == undefined ) {
      _this.data = null; 
      return;
    } else {
      _this.data = window.allProjects;
    }

    if ( _this.data.features.length > 0 ) {
      _this.projects = _this.data.features;
    } else {
      return;
    }
  
    _this.initMap();
  },

  destroy: function() {
    this.data = null;
    this.projects = null;

    this.destroyMap();
  }
}